<template>
  <div class="batchDelivery">
    <div class="title">出货</div>
    <div class="describe">同时申请、列印多比订单的运送标签!</div>
    <div class="main">
      <div class="top">
        <i class="el-icon-box" style="margin: 0 6px 0 20px"></i>
        <span>待出货</span>
        <div class="line"></div>
      </div>
      <div class="in">
        <div style="display: flex; align-items: center">
          <div
            style="
              width: 60px;
              font-size: 14px;
              margin-left: 20px;
              margin-right: 10px;
            "
          >
            Product
          </div>
          <el-input
            placeholder="请输入内容"
            style="width: 700px; margin-right: 10px"
            v-model="searchTerm"
            size="small"
            class="input-with-select"
          >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
          <el-button size="small" style="min-width: 80px" plain @click="reset"
            >重设</el-button
          >
          <el-button
            size="small"
            style="min-width: 80px"
            type="primary"
            @click="getList"
            >搜索</el-button
          >
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 30px;
          "
        >
          <div>
            <el-select
              size="small"
              v-model="orderType"
              placeholder="全部订单类型"
            >
              <el-option label="全部" :value="0"> </el-option>
              <el-option label="单个商品" :value="1"> </el-option>
              <el-option label="多个商品" :value="2"> </el-option>
            </el-select>
            <el-select
              style="margin-left: 10px"
              size="small"
              v-model="status"
              placeholder="全部订单"
            >
              <el-option label="全部订单" :value="0"> </el-option>
              <el-option label="处理中" :value="1"> </el-option>
            </el-select>
          </div>
          <div>
            <el-date-picker
              size="small"
              v-model="start_time"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="Order confirmed date"
            >
            </el-date-picker>
            <span> - </span>
            <el-date-picker
              size="small"
              v-model="end_time"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              default-time="23:59:59"
              placeholder="Order confirmed date"
            >
            </el-date-picker>
          </div>
        </div>
        <div style="display: flex; justify-content: right; margin-top: 20px">
          <el-button size="small" type="primary" @click="allShipment"
            >大量寄件</el-button
          >
          <el-button size="small" icon="el-icon-sort" @click="patFound"
            >排序</el-button
          >
        </div>
        <div class="list">
          <div class="head">
            <div style="width: 5%">
              <!-- <input
                class="input"
                v-model="alls"
                type="checkbox"
                @change="all"
              /> -->
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="alls"
                @change="all"
                :disabled="listData.list.length == 0"
              ></el-checkbox>
            </div>
            <div style="width: 10%">商品</div>
            <div style="width: 15%">订单编号</div>
            <div style="width: 12%">买家</div>
            <div style="width: 18%">物流选项</div>
            <div style="width: 22%">订单状态</div>
            <div style="width: 15%">操作</div>
          </div>
          <div v-if="httpsShow">
            <div v-if="listData.list.length != 0">
              <div class="item" v-for="(item, i) in listData.list" :key="i">
                <div style="width: 5%">
                  <el-checkbox-group
                    text-color="#fff"
                    v-model="checkedCities"
                    @change="single"
                  >
                    <el-checkbox :label="item.id">&ensp;</el-checkbox>
                  </el-checkbox-group>
                </div>
                <div style="width: 10%">
                  <el-image
                    style="width: 60px; height: 60px"
                    :src="item.orderGoods[0].image"
                    fit="fit"
                  >
                    <svg
                      slot="error"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 54 61"
                      fill="#e5e4e4"
                      style="width: 50px; height: 50px"
                    >
                      <path
                        d="M51.2 16.9H38.7C38.7 11.6 36 .6 27 .5 17.4.4 15.2 12.4 15.2 16.9H2.8c-3.4 0-2.7 3.4-2.7 3.4l2.4 33s-.1 7.3 6.3 7.5h36.5c6.2-.4 6.3-7.5 6.3-7.5l2.4-33c0-.1.5-3.5-2.8-3.4zM27.1 4.2c7.1.2 7.9 11.7 7.7 12.6H19.1c-.1-.9.4-12.4 8-12.6zm9.1 44.6c-1 1.7-2.7 3-5 3.7-1.2.4-2.4.5-3.6.5-3.2 0-6.5-1.1-9.3-3.3-.8-.6-1-1.5-.5-2.3.2-.4.7-.7 1.2-.8.4-.1.9 0 1.2.3 3.2 2.4 8.3 4 11.9 1.6 1.4-.9 2.1-2.7 1.6-4.3-.5-1.6-2.2-2.7-3.5-3.4-1-.6-2.1-1-3.3-1.4-.9-.3-1.9-.7-2.9-1.2-2.4-1.2-4-2.6-4.8-4.2-1.2-2.3-.6-5.4 1.4-7.5 3.6-3.8 10-3.2 14-.4.9.6.9 1.7.4 2.5s-1.4.9-2.2.4c-2-1.4-4.4-2-6.4-1.7-2 .3-4.7 2-4.4 4.6.2 1.5 2 2.6 3.3 3.3.8.4 1.5.7 2.3.9 4.3 1.3 7.2 3.3 8.6 5.7 1.2 2.1 1.2 4.9 0 7z"
                      /></svg
                  ></el-image>
                </div>
                <div style="width: 15%; color: #ff9900">
                  {{ item.order_no }}
                </div>
                <div style="width: 12%">{{ item.ship_name }}</div>
                <div style="width: 18%">{{ item.freight_name }}</div>
                <div style="width: 22%; color: #3399ff">
                  请在{{ item.state_time }}前完成出货
                </div>
                <div
                  style="width: 15%; color: #3399ff; cursor: pointer"
                  @click="applyShipment(item)"
                >
                  申请出货编号
                </div>
              </div>
            </div>
            <div v-else><el-empty description="暂无订单"></el-empty></div>
          </div>
          <div v-else style="text-align: center; padding-top: 20px">
            <img :src="lodingImg" alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- 申请出货编号 -->
    <el-dialog
      :title="'出货' + singleData.ship_no"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
    >
      <div
        style="
          display: flex;
          background: #fafafa;
          padding: 20px;
          border-radius: 4px;
          margin-top: -10px;
        "
      >
        <div style="margin-right: 10px">
          <i class="el-icon-location-information"></i>
        </div>
        <div style="color: #949494; font-size: 14px">
          <div>
            {{ singleData.ship_name }}，(+86)
            {{ singleData.mobile }}
          </div>
          <div style="margin-top: 8px">
            {{ singleData.address }}， {{ singleData.country }}，
            {{ singleData.zip_code }}，
          </div>
        </div>
      </div>
      <div>
        <div style="font-size: 14px; margin: 20px 0 10px 0">物流选项</div>
        <div style="margin-bottom: 20px">
          <el-select
            size="mini"
            v-model="shipment.logisticsId"
            placeholder="请选择"
          >
            <el-option
              v-for="item in logisticsList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <div style="font-size: 14px; margin-bottom: 10px">
            取货编号/交货便代码
          </div>
          <div>
            <el-input
              size="mini"
              v-model="shipment.code"
              placeholder="(选填)"
            ></el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="shipmentConfirm">出 货</el-button>
      </span>
    </el-dialog>

    <!-- 大量寄件 -->
    <el-dialog
      title="批量出货"
      :visible.sync="dialogVisible1"
      width="600px"
      :before-close="handleClose1"
    >
      <div>
        <div style="font-size: 14px; margin: 20px 0 10px 0; margin-top: -20px">
          物流选项
        </div>
        <div style="margin-bottom: 20px">
          <el-select
            size="mini"
            v-model="shipment.logisticsId"
            placeholder="请选择"
          >
            <el-option
              v-for="item in logisticsList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <div style="font-size: 14px; margin-bottom: 10px">
            取货编号/交货便代码
          </div>
          <div>
            <el-input
              size="mini"
              v-model="shipment.code"
              placeholder="(选填)"
            ></el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose1">取 消</el-button>
        <el-button type="primary" @click="allShipmentConfirm">出 货</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  batchShipment,
  applyShipNumber,
  bulkShipping,
} from "@/api/logistics.js";
import { logisticsList } from "@/api/common.js";
import env from "@/settings/env.js";
export default {
  data() {
    return {
      baseURL: env.fileUrlPrefix,
      alls: false,
      listData: {
        list: [],
      },
      searchTerm: "",
      orderType: 0,
      status: 0,
      start_time: "",
      end_time: "",
      order: 0,
      singleData: "",
      allData: "",
      dialogVisible: false,
      dialogVisible1: false,
      shipment: {
        logisticsId: "",
        code: "",
        id: "",
      },
      logisticsList: "",
      checkedCities: [],
      isIndeterminate: false,
      httpsShow: false,
      lodingImg: require("@/assets/img/app/http.gif"),
    };
  },
  created() {
    this.getList();
    this.getLogisticesList();
  },
  methods: {
    getList() {
      this.httpsShow = false;
      batchShipment({
        goods_name: this.searchTerm,
        order_type: this.orderType,
        status: this.status,
        start_time: this.start_time,
        end_time: this.end_time,
        order: this.order,
        page: 1,
        page_size: 10,
      }).then((res) => {
        this.httpsShow = true;
        if (res.code == 1) {
          this.listData = res.data;
          this.listData.list.forEach((item) => {
            item.show = false;
          });
        }
      });
    },
    getLogisticesList() {
      logisticsList().then((res) => {
        if (res.code == 1) {
          this.logisticsList = res.data;
          this.shipment.logisticsId = this.logisticsList[0].id;
        }
      });
    },
    all(val) {
      let arr = [];
      if (val) {
        this.listData.list.forEach((item) => {
          arr.push(item.id);
        });
      }
      this.checkedCities = arr;
      this.isIndeterminate = false;
    },
    single(value) {
      let checkedCount = value.length;
      this.alls = checkedCount == this.listData.list.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.listData.list.length;
    },
    reset() {
      this.searchTerm = "";
      this.orderType = 0;
      this.status = 0;
      this.start_time = "";
      this.end_time = "";
      this.order = 0;
      this.getList();
    },
    applyShipment(item) {
      this.singleData = item;
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
      this.shipment = {
        logisticsId: (this.shipment.logisticsId = this.logisticsList[0].id),
        code: "",
        id: "",
      };
    },
    handleClose1() {
      this.dialogVisible1 = false;
      this.shipment = {
        logisticsId: (this.shipment.logisticsId = this.logisticsList[0].id),
        code: "",
        id: "",
      };
    },
    // 单个出货
    shipmentConfirm() {
      let logisticsName = "";
      this.logisticsList.forEach((item) => {
        if (this.shipment.logisticsId == item.id) {
          logisticsName = item.name;
        }
      });
      applyShipNumber({
        id: this.singleData.id,
        fright_id: this.shipment.logisticsId,
        fright_name: logisticsName,
        shipment_number: this.singleData.ship_no,
        pickup_num: this.shipment.code,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.message + "，请在我的物流中查看");
          this.dialogVisible = false;
          this.getList();
        }
      });
    },
    // 批次出货
    allShipmentConfirm() {
      bulkShipping({
        ids: this.checkedCities.join(","),
        freight_id: this.shipment.logisticsId,
        delivery_no: this.shipment.code,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.message + "，请在我的物流中查看");
          this.dialogVisible1 = false;
          this.getList();
        }
      });
    },
    allShipment() {
      if (this.checkedCities.length == 0) {
        this.$message.error("当前没有选中项");
        return false;
      }
      this.dialogVisible1 = true;
      let arr = [];
      this.listData.list.forEach((item) => {
        if (item.show) {
          arr.push(item.id);
        }
      });
      this.allData = arr;
    },
    patFound() {
      this.order = this.order == 1 ? 0 : 1;
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.batchDelivery {
  padding: 0 20px;
  .title {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .describe {
    font-size: 14px;
    color: #8c8c8c;
  }
  .main {
    width: 100%;
    background: #fff;
    border-radius: 4px;
    margin-top: 30px;
    .top {
      color: #ee4d2d;
      line-height: 56px;
      font-size: 14px;
      border-bottom: 1px solid #e5e5e5;
      position: relative;
      .line {
        width: 100px;
        height: 3px;
        background: #ee4d2d;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    .in {
      margin-top: 20px;
      padding: 20px 20px;
      .list {
        border-radius: 4px;
        border: 1px solid #e9e9e9;
        margin-top: 20px;
        .head {
          display: flex;
          background: #f6f6f6;
          border-bottom: 1px solid #e9e9e9;
          font-size: 14px;
          color: #717373;
          padding: 16px 20px;

          .input[type="checkbox"] {
            width: 18px;
            height: 18px;
            margin-top: 2px;
            position: relative;
          }
          .input[type="checkbox"]::after {
            position: absolute;
            top: 0;
            color: #000;
            width: 18px;
            height: 18px;
            display: inline-block;
            visibility: visible;
            padding-left: 0px;
            text-align: center;
            content: " ";
            border-radius: 1.8px;
          }
          .input[type="checkbox"]:checked::after {
            content: "✓";
            color: #fff;
            font-size: 10px;
            line-height: 18px;
            background-color: #ef5738;
          }
        }
        .item {
          display: flex;
          border-bottom: 1px solid #e9e9e9;
          font-size: 15px;
          padding: 20px 20px;
          align-items: center;

          .input[type="checkbox"] {
            width: 18px;
            height: 18px;
            margin-top: 2px;
            position: relative;
          }
          .input[type="checkbox"]::after {
            position: absolute;
            top: 0;
            color: #000;
            width: 18px;
            height: 18px;
            display: inline-block;
            visibility: visible;
            padding-left: 0px;
            text-align: center;
            content: " ";
            border-radius: 1.8px;
          }
          .input[type="checkbox"]:checked::after {
            content: "✓";
            color: #fff;
            font-size: 10px;
            line-height: 18px;
            background-color: #ef5738;
          }
        }
      }
    }
  }
}
</style>